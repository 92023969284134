import React from "react";

import Carousel from "./components/carousel/Carousel";
import Consultation from "./components/consultation/Consultation";
import About from "./components/about/About";
import Treatment from "./components/treatment/Treatment";
import Testimonials from "./components/testimonials/Testimonials";
import Contact from "./components/contact/Contact";

function Home() {
  return (
    <div>
      <Carousel />
      <Consultation />
      <About />
      <Treatment />
      <Testimonials />
      <Contact />
    </div>
  );
}

export default Home;
