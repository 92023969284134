import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

import Navbar from "./components/navbar/Navbar";
import Home from "./Home";
import Gallery from "./Gallery";
import Footer from "./components/footer/Footer";

import Fetch from "./Fetch";

const App = () => {
  return (
    <div className="App">
      <Navbar />
      <div className="content">
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/gallery" element={<Gallery />} />
            <Route exact path="/fetch" element={<Fetch />} />
          </Routes>
        </BrowserRouter>
      </div>
      <Footer />
    </div>
  );
};

export default App;
