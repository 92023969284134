import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

import "./consultation.css";

export const Consultation = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_8bs7wva",
        "template_gkqmhwa",
        form.current,
        "4Hc04dWiwLtTQRmUk"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <div className="about-widget">
      <div className="container">
        <div className="about-inner">
          <div className="about-easy-gst">
            <h3 className="head03">
              <span>We use the most modern Medical Softwares to provide</span>{" "}
              fully computerized care.
            </h3>
            <p>
              Provide safe, cost -effective and high quality Homoeopathic
              treatment for all major and minor diseases. A multi speciality
              Homoeopathic clinic including rheumatic, neuromuscular,
              respiratory, sexual, gynaecological including male &amp; female
              infertility, urinary, paediatric, psychological treatment etc
              without any side effects.
            </p>
            <p>
              <b>
                If you are not able to reach our clinic for direct consultation,
                we reach you online through supporting platforms (google meet,
                zoom, whatsApp).
              </b>
            </p>
          </div>
          {/* <div class="home-gst-form easy-gst-form">
            <div class="caption">
              <h3 class="head03">
                <span>BOOK CONSULTATION</span>
              </h3>
              <i className="fa-solid fa-phone-volume"></i>
              <a href="tel:8778435478">+91 8778435478</a>
              <h4>No Consultation on Friday Evening and Saturday</h4>
            </div>
            <form ref={form} onSubmit={sendEmail}>
              <div class="form_list">
                <input
                  name="name"
                  required
                  placeholder="Your name"
                  className="text_field"
                  type="text"
                />
                <input
                  name="phone"
                  required
                  placeholder="Phone Number"
                  className="text_field"
                  type="text"
                />
                <input
                  name="email"
                  required
                  placeholder="Email Address"
                  className="text_field"
                  type="text"
                />
                <select className="select_field" name="preferredtime">
                  <option value="">Preferred Time</option>
                  <option value="9am to 12am">9am to 12am</option>
                  <option value="3pm to 6pm">3pm to 6pm</option>
                </select>

                <textarea
                  rows="4"
                  cols="50"
                  placeholder="Reason for treatment"
                  name="reason"
                />

                <input
                  id="sendMessage"
                  type="submit"
                  name="sendMessage"
                  value="Submit your details"
                  className="button form-btn esein4"
                />
              </div>
            </form>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Consultation;
