import React from "react";

import "./contact.css";
import gpay from "../../assets/google-pay.png";

function Contact() {
  return (
    <div className="contact">
      <div className="contact">
        <span className="text01">Interested to know more? Contact Us</span>
      </div>
      <div className="row">
        <div className="col">
          <i className="far fa-envelope" aria-hidden="true"></i>
          <a href="mailto:homcareclinic@gmail.com">homcareclinic@gmail.com</a>
        </div>
        <div className="col">
          <i className="fa-solid fa-phone-volume"></i>
          <a href="tel:8778435478">+91 8778435478</a>
        </div>
        <div className="col">
          <img src={gpay} className="gpay" width="55px" height="45px" />
          <a href="#">9843043113</a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
