import React from "react";

import "./about.css";
import melvin from "../../assets/melvin.jpg";
import facebook from "../../assets/facebook.png";
import youtube from "../../assets/youtube.png";

function About() {
  return (
    <div className="about">
      <div class="container">
        <h3 class="head03">
          {" "}
          <span>About</span>
        </h3>
        <div class="row">
          <div class="col-md-6">
            <div class="doctor-profile">
              <div class="doctor-avatar">
                <img src={melvin} />
              </div>
            </div>
          </div>
          <div class="col-md-6 doctor-details">
            <h4>PROF. DR. K. MELVIN ABRAHAM, MD (Hom.)</h4>
            <h4>PAEDIATRICS</h4>
            <h5>23 YEARS OF MEDICAL EXPERIENCE</h5>
            <ul class="social-icons d-flex">
              <li>
                <a href="https://www.facebook.com/profile.php?id=100089456622700">
                  <img src={facebook} width="35px" height="35px" />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src={youtube} width="35px" height="35px" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
