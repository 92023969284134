import React from "react";

import "./carousel.css";

import image1 from "../../assets/image1.jpg";
import image4 from "../../assets/image4.jpg";
import image6 from "../../assets/image6.jpg";
import image7 from "../../assets/image7.jpg";

function Carousel() {
  return (
    <div>
      <div id="carouselExample" class="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="shapes"></div>
            <div className="shapes2"></div>
            <div className="shapes3"></div>
            <img src={image1} className="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src={image4} className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item">
            <img src={image6} className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item">
            <img src={image7} className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item">
            <img
              src="assets/images/image8.jpg"
              className="d-block w-100"
              alt="..."
            />
          </div>
          <div className="carousel-item">
            <img
              src="assets/images/image9.jpg"
              className="d-block w-100"
              alt="..."
            />
          </div>
          <div className="carousel-item">
            <img
              src="assets/images/image10.jpg"
              className="d-block w-100"
              alt="..."
            />
          </div>
          <div className="carousel-item">
            <img
              src="assets/images/image11.jpg"
              className="d-block w-100"
              alt="..."
            />
          </div>
          <div className="carousel-item">
            <img
              src="assets/images/image12.jpg"
              className="d-block w-100"
              alt="..."
            />
          </div>
          <div className="carousel-item">
            <img
              src="assets/images/image13.jpg"
              className="d-block w-100"
              alt="..."
            />
          </div>
          <div className="carousel-item">
            <img
              src="assets/images/image14.jpg"
              className="d-block w-100"
              alt="..."
            />
          </div>
          <div className="carousel-item">
            <img
              src="assets/images/image15.jpg"
              className="d-block w-100"
              alt="..."
            />
          </div>
          <div className="carousel-item">
            <img
              src="assets/images/image16.jpg"
              className="d-block w-100"
              alt="..."
            />
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExample"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExample"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}

export default Carousel;
