import React, { useState } from "react";

const Fetch = () => {
  const [users, setUsers] = useState([]);
  const [currentFruit, setCurrentFruit] = useState(["Leanne Graham"]);

  const fetchData = (newfruit) => {
    console.log(newfruit);
    setCurrentFruit(newfruit);
    fetch(`https://jsonplaceholder.typicode.com/posts?userId=${newfruit}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setUsers(data);
      });
  };

  return (
    <div>
      <select
        onChange={(event) => fetchData(event.target.value)}
        value={currentFruit}
      >
        <option value="1">Leanne Graham</option>
        <option value="2">Outrageous Oranges</option>
        <option value="c">Technically a Fruit Tomatoes</option>
        <option value="d">Bodacious Bananas</option>
      </select>

      {users.length > 0 && (
        <ul>
          {users.map((user) => (
            <li key={user.id}>{user.title}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Fetch;
