import React from "react";

import "./treatment.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenNib } from "@fortawesome/free-solid-svg-icons";

function Treatment() {
  return (
    <div className="treatments">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="course-list-panel">
              <div className="course-name-widget">
                <h3 className="head03">WHY HOMOEOPATHY?</h3>
                <ul>
                  <li>
                    <FontAwesomeIcon icon={faPenNib} />
                    Boost's Immunity
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faPenNib} />
                    No unwanted side effects
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faPenNib} />
                    Medicines are Easy to Consume
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faPenNib} />
                    Homoeopathic Medicines are liked by Children
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faPenNib} />
                    Treat all kind of Diseases
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faPenNib} />
                    Preventives or Immunity boosters for all kind of Infectious
                    diseases
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <h3 className="head03">OUR HOMOEOPATHIC TREATMENT</h3>
            <button type="button" className="btn btn-outline-primary">
              Allergic Complaints
            </button>
            <button type="button" className="btn btn-outline-primary">
              Autism
            </button>
            <button type="button" className="btn btn-outline-primary">
              Anxiety
            </button>
            <button type="button" className="btn btn-outline-primary">
              Asthma
            </button>
            <button type="button" className="btn btn-outline-primary">
              Addictions
            </button>
            <button type="button" className="btn btn-outline-primary">
              Arthritis
            </button>
            <button type="button" className="btn btn-outline-primary">
              Blood Pressure
            </button>
            <button type="button" className="btn btn-outline-primary">
              Diabetes
            </button>
            <button type="button" className="btn btn-outline-primary">
              Diabetic Gangrene
            </button>
            <button type="button" className="btn btn-outline-primary">
              Depression
            </button>
            <button type="button" className="btn btn-outline-primary">
              Hormonal Defects
            </button>
            <button type="button" className="btn btn-outline-primary">
              Infections
            </button>
            <button type="button" className="btn btn-outline-primary">
              Kidney troubles
            </button>
            <button type="button" className="btn btn-outline-primary">
              Menstrual Irregularities
            </button>
            <button type="button" className="btn btn-outline-primary">
              Obesity
            </button>
            <button type="button" className="btn btn-outline-primary">
              PCOD
            </button>
            <button type="button" className="btn btn-outline-primary">
              Rheumatic Complaints
            </button>
            <button type="button" className="btn btn-outline-primary">
              Skin troubles
            </button>
            <button type="button" className="btn btn-outline-primary">
              Sexual Dysfunctions
            </button>
            <button type="button" className="btn btn-outline-primary">
              Stress
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Treatment;
