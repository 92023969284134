import React from "react";

import "./testimonials.css";

function Testimonials() {
  return (
    <div className="testimonials">
      <h3 className="head03">
        <span>Testimonials</span>
      </h3>
      <div className="container">
        <div className="row justify-content-evenly">
          <div className="col-md-6">
            <p>
              Ennaku 2 years aa UTI problem erunthuchu. Allopathy medicine
              eduthum seri aagala. Unga kitta homeopathy medicine one course
              eduthathum seri aagiduchi. Thank you
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
