import React from "react";

import "./footer.css";

function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <div className="ftr-bottom-widget cf">
          Copyright © 2023 homcare.com. All rights reserved.
        </div>
      </div>
    </div>
  );
}

export default Footer;
